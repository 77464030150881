/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        // Fastclick
        FastClick.attach(document.body);

        // Remove empty p tags
        $('p:empty').remove();

        // External Links.
        $('a').filter(function () {
          return this.hostname && this.hostname !== location.hostname;
        }).addClass('external').attr('target', '_blank');

        // Dropdown Menu
        if (viewportWidth <= 992) {
          $('.c-canvas__toggle').click(function (e) {
            e.preventDefault();

            $('.c-canvas').toggleClass('nav-is-showing');
          });

          $('.dropdown-toggle').click(function () {
            $(this).next().toggleClass('dropdown-is-showing');
          });
        }

        // House of Windsor Section 9
        $desktop_image = $('.c-blog-section--9').data('desktop');

        if ($('.c-blog-section--9').length) {
          $('.c-blog-section--9 .o-container').css('background-image', 'url(' + $desktop_image + ')');
        }

        // Share
        if ($('.c-share__print').length) {
          $('.c-share__print').click(function () {
            window.print();
          });
        }

        if ($('.c-share__social').length) {
          $('.c-share__social').click(function (e) {
            e.preventDefault();

            $('.c-share__box').show();
          });

          $('.c-share__box-close').click(function (e) {
            e.preventDefault();

            $('.c-share__box').hide();
          });
        }

        // Responsive videos
        if ($('.o-video').length) {
          // Find all YouTube videos
          var $allVideos = $("iframe[src^='//player.vimeo.com'], iframe[src^='https://www.youtube.com']"),

            // The element that is fluid width
            $fluidEl = $(".o-video");

          // Figure out and save aspect ratio for each video
          $allVideos.each(function () {

            $(this)
              .data('aspectRatio', this.height / this.width)

              // and remove the hard coded width/height
              .removeAttr('height')
              .removeAttr('width');

          });

          // When the window is resized
          $(window).resize(function () {

            var newWidth = $fluidEl.width();

            // Resize all videos according to their own aspect ratio
            $allVideos.each(function () {

              var $el = $(this);
              $el
                .width(newWidth)
                .height(newWidth * $el.data('aspectRatio'));

            });

            // Kick off one resize to fix all videos on page load
          }).resize();
        }

        // House of Windsor
        if ($('body.blog').length || $('body.single').length) {
          var moreText = 'Read More',
            lessText = 'Read Less';

          $('.c-blog-section__toggle').append('Read More');

          $('.c-blog-section__toggle').click(function (e) {
            e.preventDefault();

            var section = $(this).attr('href');

            var buttonText = $('.c-blog-section__more--desktop').is(':visible') ? moreText : lessText;

            $(this).text(buttonText);

            $(section).slideToggle();
          });
        }

        // Site Scrolling
        $(window).scroll(function () {
          var scrollY = $(this).scrollTop();

          if (scrollY > 10) {
            $('body').addClass('is-scrolling');
          } else {
            $('body').removeClass('is-scrolling');
          }

          if (scrollY > 300) {
            $('.js-scroll-up').addClass('is-active');
          } else {
            $('.js-scroll-up').removeClass('is-active');
          }
        });

        // Scroll Back Top
        $('.js-scroll-up').click(function (e) {
          $('html, body').animate({
            scrollTop: 0
          }, 1000);
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
        if ($('.js-carousel-hero').length) {
          $('.js-carousel-hero').slick({
            accessibility: true,
            adaptiveHeight: false,
            autoplay: true,
            autoplaySpeed: 4000,
            arrows: false,
            fade: true,
            pauseOnFocus: false,
            pauseOnHover: false,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
          });
        }
      }
    },
    // Textiles & Fabrics
    'textiles_and_fabrics': {
      init: function () {
        if ($('.col-3').length) {
          $('.col-3 img').on('click', function () {
            var image = $(this).attr('src');

            $('.col-9 img').attr('src', image);
          });
        }
      }
    },
    // Products
    'single_products': {
      init: function () {
        $('.c-product__media__featured').magnificPopup({
          delegate: 'a',
          type: 'image',
          image: {
            titleSrc: function (item) {
              return item.el.attr('title');
            }
          }
        });

        // Switch featured image
        if ($('.c-product__media__thumbs').length) {
          $('.c-product__media__thumbnail').on('click', function () {
            var image = $(this).data('original');

            $('.c-product__media__featured a').attr('href', image);
            $('.c-product__media__featured img').attr('src', image);
          });
        }
      }
    },
    // House of Windsor
    'blog': {
      init: function () {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
